import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Layout from 'components/Layout/Layout'

import gridStyles from 'styles/grid.module.scss'

const CustomErrorPage = ({ location }) => (
  <Layout location={location}>
    <div className={gridStyles.lbhContainer}>
      <Helmet title={'Hackney Council | Something went wrong'} />
      <div style={{ marginBottom: '3rem' }}>
        <h1>Something went wrong.</h1>
        <p>We are sorry, but there was an error. Please try again later.</p>
      </div>
    </div>
  </Layout>
)

CustomErrorPage.propTypes = {
  location: PropTypes.shape({}).isRequired,
}

export default CustomErrorPage
